<template>
  <div class="form">
    <div class="form__fieldset">
      <div class="row">
        <div class="col-12">
          <div class="fve">
            <div class="fve-label">Название</div>
            <div class="fve-field">
              <div class="fve-control">
                {{tariff.name}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="fve">
            <div class="fve-label">Количество тренировок</div>
            <div class="fve-field">
              <div class="fve-control">
                {{tariff.trainings}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="fve">
            <div class="fve-label">Количество тренирующихся</div>
            <div class="fve-field">
              <div class="fve-control">
                {{tariff.capacityFix}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="fve">
            <div class="fve-label">Продолжительность</div>
            <div class="fve-field">
              <div class="fve-control">
                {{tariff.length}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="fve">
            <div class="fve-label">Стоимость</div>
            <div class="fve-field">
              <div class="fve-control">
                {{tariff.price}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="fve">
            <div class="fve-label">Формат</div>
            <div class="fve-field">
              <div class="fve-control fve-control--inline">
                {{ ageList[tariff.age] }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="fve">
            <div class="fve-label">Тип</div>
            <div class="fve-field">
              <div class="fve-control fve-control--inline">
                {{ typeList[tariff.type] }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="fve">
            <div class="fve-label">Площадка</div>
            <div class="fve-field">
              <div class="fve-control">
                {{ (tariff.court && tariff.court.name) ? tariff.court.name + (tariff.court.club_name ? (' - ' + tariff.court.club_name) : '' ) : ''}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="fve">
            <div class="fve-label">Тренер</div>
            <div class="fve-field">
              <div class="fve-control">
                {{tariff.trainer ? tariff.trainer.last_name + ' ' + tariff.trainer.first_name + ' ' + tariff.trainer.middle_name : ''}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="fve">
            <div class="fve-label">Направление</div>
            <div class="fve-field">
              <div class="fve-control">
                {{tariff.tag ? tariff.tag.tag : ''}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'TariffEditView',
  props: {
    tariff: Object,
  },
  data() {
    return {
      ageList: {},
      typeList: {},
    };
  },
  created() {
    RequestManager.Reference.getClubAgeList({}).then((list) => {
      const _ageList = {};
      list.forEach(i => _ageList[i.key] = i.value);
      this.ageList = _ageList;
    });
    RequestManager.Reference.getTrainGroupTypeList({}).then((list) => {
      const _typeList = {};
      list.forEach(i => _typeList[i.key] = i.value);
      this.typeList = _typeList;
    });
    this.load();

  }
};

</script>

<style lang="scss" scoped>
// fve
.fve {
  margin-bottom: 24px;
  .fve-label {
    display: inline-block;
    margin: 0 0 4px 0;
    padding: 0 0 0 0;
    color: #ACACAC;
    font-size: 14px;
    line-height: inherit;
    transition: color 0.15s ease-in-out;
  }
  .fve-control {
    padding: 11px 16px;
    color: var(--text-sub);
    font-size: 17px;
    font-weight: 400;
    background-color: #2D2D2D;
    border-radius: 4px;
    opacity: 0.5;
    &--inline {
      display: inline-block;
    }
  }
}
</style>
