<template>
  <div class="container">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Редактировать тарифа</h1>
      </div>
      <div class="page-header__ell">
        <template v-if="tariff">
          <FveBlockSwitchActive
            v-model="is_active"
          />
        </template>
      </div>
    </div>

    <template v-if="tariff">
      <TariffEditView
        :tariff="tariff"
      />
    </template>

    <form class="form">
      <div class="btn-gp">
        <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
        <router-link :to="{name: this.$routeName.TARIFF_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
      </div>
    </form>
  </div>
</template>

<script>

import TariffEditView from "./_component/TariffEditView";
import FveBlockSwitchActive from "@widgetFormValidate/src/Element/Switch/FveBlockSwitchActive";

export default {
  name: "ClubCardAdd",
  components: {
    TariffEditView,
    FveBlockSwitchActive,
  },
  props: {
    tariffId: [String, Number],
  },
  data() {
    return {
      is_active: true,
      tariff: {},
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load(){
      RequestManager.Tariff.getTariff({
        tariffId: this.tariffId,
      }).then((data) => {
        this.tariff = Object.assign({}, data);
        this.tariff.capacityFix = this.tariff.capacity - 1;
        this.is_active = data.is_active;
      });
    },
    async save() {

      RequestManager.Tariff.updateTariff({
        tariffId: this.tariffId,
        tariff: {
          is_active: this.is_active,
          // tariff: this.clubCard.tariff.id,
        }
      } ).then( (res) => {
        this.$toasts.push({
          message: 'Тариф успешно обновлен',
          duration: 4000,
        });
        this.$router.push({name: this.$routeName.TARIFF_LIST });
      });
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
